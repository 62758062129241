import React from "react"

import Layout from "../components/layout/main"
import { Box, Paper, Typography } from "@material-ui/core"
// import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Box my={3}>
      <Paper elevation={4}>
        <Box p={3} textAlign="center">
          <Typography variant="h4" component="h1" color="secondary">
            <Box fontWeight={500}>404 - Sayfa Bulunamadı</Box>
          </Typography>
          <p style={{ fontSize: "1.2em" }}>
            Ulaşmaya çalıştığınız sayfa mevcut değildir. Menü kısmından sayfalara bakabilirsiniz.
          </p>
        </Box>
      </Paper>
    </Box>
  </Layout>
)

export default NotFoundPage
